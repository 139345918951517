<template>
    <div>

        <div v-for="data in list_data" :key="data.idx">
        <!-- slider Area Start-->
            <div class="slider-area ">
                <!-- Mobile Menu -->
                <div class="single-slider slider-height2 d-flex align-items-center" data-background="assets/img/hero/about.jpg">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="hero-cap text-center">
                                    <h2>{{data.m_jenispariwisata_uraian}}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- slider Area End-->
        <div class="favourite-place place-padding" style="margin-top: -5%">
            <div class="container">
            <!-- Section Tittle -->

        
            <br>
            






                <div class="row">
                    <div class="col-lg-8 posts-list">
                    <div class="single-post">
                        <div class="feature-img">
                            <!-- <img class="img-fluid" src="assets/img/blog/single_blog_1.png" alt=""> -->
                            <img class="img-fluid" style="width:100%" :src="$store.state.url.URL_APP+'uploads/'+data.fileThumb" alt="">
                        </div>
                        <div class="blog_details">
                            <h2>{{data.uraian}}</h2>
                            <ul class="blog-info-link mt-3 mb-4">
                                <li><a href="#"><i class="fa fa-university"></i> Tatakelola {{data.m_jenistatakelola_uraian}}</a></li>
                                <li><a href="#"><i class="fa fa-calendar"></i> 03 Nov 2021</a></li>
                            </ul>
                            
                            <div class="ql-editor" v-html="UMUM.replaceEscapeString(data.keterangan)"></div>
                        </div>
                    </div>

                    <br>
                    <div class="blog-author">
                        
                        <div class="row">
                            <div class="col-2">
                                <img class="img-fluid" src="img/navigateTo.png" alt="">
                            </div>
                            <div class="col-10">
                                <span class="h_kecil_title">DESA/KEL. {{data.nama_des_kel}}</span> <br>
                                <span class="h_kecil_title">KEC. {{data.nama_kecamatan}}</span> <br>
                                <span class="h_kecil_title">{{data.nama_kabupaten}}</span>
                            </div>
                        </div>
                    </div>

                    </div>
                    <div class="col-lg-4">
                    <div class="blog_right_sidebar">

                        <aside class="single_sidebar_widget post_category_widget">
                            <h4 class="widget_title">Fasilitas</h4>
                            <ul class="list cat-list">
                                <li v-for="data in list_data_fasilitas" :key="data.id">
                                    <a href="#" class="d-flex">
                                        <p>{{data.uraian}}</p>
                                        <p> ({{data.jumlah}} {{data.satuan}})</p>
                                    </a>
                                </li>
                            
                            </ul>
                        </aside>


                        <destinasiLainnya/>

                        <fotoLainnya/>



                    </div>
                    </div>
                </div>















            <hr>


            </div>
        </div>

            <!-- <script type="application/javascript" defer src="./assets/js/vendor/jquery-1.12.4.min.js"></script> -->


            <script type="application/javascript" defer src="./assets/js/plugins.js"></script>
            <script type="application/javascript" defer src="./assets/js/main.js"></script>
        </div>
    </div>
</template>

<script>


import FETCHING from  '../../library/fetching'
import UMUM from  '../../library/umum'


export default {
  data() {
    return {
        idx : '',
        list_data : [],
        list_data_fasilitas : [],
    
        list_jenis_wisata : [],
        bidangPariwisata : 2,



        m_jenisPariwisata : '',

        FETCHING : FETCHING,
        UMUM : UMUM,


        loadJenis : false
    }
  },

  methods: {
    getView: function() {
      fetch(this.$store.state.url.URL_WISATA_OBJEK + "getOne", {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          keyx : this.idx,
        })
      })
        .then(res => res.json())
        .then(res_data => {
            this.list_data = res_data;
            // console.log(res_data);


            var idxy = res_data[0].id
            this.getViewFasilitas(idxy);
        });
    },



    getViewFasilitas: function(idxy) {
      fetch(this.$store.state.url.URL_WISATA_OBJEK_FASILITAS + "getOne", {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          keyx : idxy,
        })
      })
        .then(res => res.json())
        .then(res_data => {
            this.list_data_fasilitas = res_data;
            // console.log(res_data);
        });
    },



    cari_data: function() {
      this.page_first = 1;
      this.getView();
    },

    fetcher : async function() {
      this.loadJenis = false
      this.list_jenis_wisata = await FETCHING.FETCH_POST(
        this.$store.state.url.URL_JENIS_WISATA + "list", 
        {bidangPariwisata : this.bidangPariwisata}
      )

      this.loadJenis = true

      // console.log(this.list_jenis_wisata)
    }
  },

  mounted () {

    this.idx = location.search.split('idx=')[1];
    // console.log(this.idx)



    this.fetcher()
    this.getView();
    
  },


};
</script>

<style>
</style>